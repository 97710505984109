<template>
    <div>
        <!-- Show desktop screens -->
        <v-container v-if="hasLevels" class="d-none d-md-flex d-lg-flex">
            <v-progress-circular class="mx-auto" indeterminate v-if="loading"/>

            <!-- ajustar alinhamento da margin -->
            <v-card v-else elevation="0" width="100%">
                <v-alert v-if="hasError" type="error" dense block class="ma-0">
                    {{ $t('views.HomeView.levels.lookback.error') }}
                </v-alert>
                <v-col v-else class="px-4">
                    <v-card-title class="primary--text font-weight-bold subtitle-1 pb-3">
                        {{ $t('views.HomeView.levels.lookback.subtitle', { levelName: progressLevel.name }) }}
                    </v-card-title>
                    <v-card-subtitle v-if="!hidePoints">{{ $tc('views.HomeView.levels.points', progressAccumulatedAmount) }}</v-card-subtitle>

                    <v-card-text>
                        <!-- Listagem de benefícios -->
                        <v-row class="mb-8 pa-0">
                            <v-col v-for="(benefit, index) in benefits" :key="benefit.id" class="pa-0">
                                <BenefitListItem class="mb-2" readonly :benefit="benefit" :index="index" completeDescription/>
                            </v-col>
                        </v-row>

                        <!-- Estrelas dos níveis -->
                        <div class="p-relative">
                            <template v-for="(item, index) in levels">
                                <div class="py-0 ml-n1" :key="index" :style="computedProgressStyles(index)">
                                    <div v-for="(item, index) in (index+1)" :key="index">
                                        <v-icon :size="20" color="primary">far fa-star</v-icon>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="clear-fix py-1"></div>

                        <!-- Barra de progresso central -->
                        <v-progress-linear 
                            background-color="progress_linear"
                            class="progress-liner"
                            :value="globalUserLevel"
                            color="primary"
                        ></v-progress-linear>

                        <div class="clear-fix py-1"></div>

                        <!-- Nomes dos níveis -->
                        <div class="p-relative">
                            <template v-for="(item, index) in levels">
                                <div class="py-0" :key="`${index}-${item.name}`" :style="computedProgressStyles(index)">
                                    <div class="primary_font--text pa-0"
                                        :style="item.name === progressLevel.name ? `color: var(--v-primary-base) !important` : ''"
                                    >{{ item.name }}</div>
                                </div>
                            </template>
                        </div>

                        <div class="clear-fix"></div>

                        <!-- Descrições dos níveis -->
                        <v-row>
                            <v-col v-for="(item, index) in levels" :key="`${index}-${item.id}`" 
                                class="vertical-line"
                            >
                                <v-col class="mt-12 px-0">
                                <v-card-title class="subtitle-1 pa-0 mt-10 font-weight-medium">
                                    {{ $t('views.HomeView.levels.LevelBenefit.title', { level_name: item.name }) }}
                                    </v-card-title>
                                    <LevelRangePoints v-if="!hidePoints" class="primary--text mb-3" :level="item"/>
                                    <span class="caption">{{ item.description }}</span>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-col>
            </v-card>
        </v-container>

        <!-- Show only mobile -->
        <v-container fluid class="d-block d-md-none">
            <v-progress-circular class="mx-auto" indeterminate v-if="loading"/>
            <v-row v-else no-gutters>                
                <v-col cols="12">
                    <v-alert v-if="hasError" type="error" dense block class="ma-0">
                        {{ $t('views.HomeView.levels.lookback.error') }}
                    </v-alert>
                    <v-card v-else outlined class="card" width="100%">
                        <v-col class="pb-0 title-content">
                            <v-card-title class="primary--text font-weight-bold pb-0">
                                {{ $t('views.HomeView.levels.lookback.subtitle', { levelName: progressLevel.name }) }}
                            </v-card-title>
                            <v-card-subtitle v-if="!hidePoints" class="subtitle-1 pt-2">{{ $tc('views.Level.points', progressAccumulatedAmount) }}</v-card-subtitle>
                        </v-col>

                        <v-col cols="12" class="pt-0 pb-10">
                            <v-row no-gutters>
                                <v-col v-for="(benefit, index) in benefits" :key="benefit.id" cols="12" xs="12" sm="12" md="6" lg="3" class="pa-0">
                                    <BenefitListItem class="mb-2" readonly :benefit="benefit" :index="index" completeDescription />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-card-text class="d-flex">
                            <div class="vertical-progress">
                                <VerticalProgressBar :value="globalUserLevel" class="mx-3 vertical-progress" />
                            </div>

                            <v-col class="pa-0 ma-0">
                                <v-col class="pa-0 ml-4 mb-6" v-for="(item, index) in levels" :key="`${index}-primary`">
                                    <div class="d-flex mb-1">
                                        <span v-for="(item, i) in (index+1)" :key="`${i}-secundary`">
                                            <v-icon :size="22" color="primary">far fa-star</v-icon>
                                        </span>
                                    </div>
                                    <div :class="progressLevel.id == item.id ? 'primary--text': 'primary_font--text'">{{ item.name }}</div>
                                    <v-col>
                                        <v-row class="subtitle-2 pa-0 flex-column mt-n2">
                                            <v-card-title class="subtitle-1 pa-0 font-weight-medium">
                                                {{ $t('views.HomeView.levels.LevelBenefit.title', { level_name: item.name }) }}
                                            </v-card-title>
                                            <LevelRangePoints v-if="!hidePoints" :level="item" class="primary--text"/>
                                            <div class="mt-4 mr-6">{{ item.description }}</div>
                                        </v-row>
                                    </v-col>
                                </v-col>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import BenefitListItem       from '@/shared/components/listItems/BenefitListItem'
import VerticalProgressBar   from '@/components/progressBars/LevelVerticalProgressBar'
import HasErrorHandlerMixin  from '@/mixins/HasErrorHandlerMixin'
import LevelRangePoints      from './components/LevelRangePoints'
import Benefit               from '@/models/Benefit'
import { mapGetters }        from 'vuex'

export default {
    name: 'LevelForLookback',
    mixins: [ HasErrorHandlerMixin ],
    components: { VerticalProgressBar, LevelRangePoints, BenefitListItem },
    data: vm => ({
        hasError        : false,
        loading         : true,
        benefits        : [],
        progressValues  : [],
        triggers        : [],
        lastTriggerValue: null,
    }),
    async created() {
        if (!this.hasLevels)
            this.$router.push({ name: 'home' })

        await this.fetchData()
    },
    methods: {
        async fetchData() {
            this.loading = true

            if (!this.levels || !this.levels.length) {
                this.hasError = true
                this.loading  = false
                return
            }

            // Atualiza o cliente a fim de garantir que o nível exibido é o mais novo possível
            await this.$store.dispatch('auth/fetchCurrentCustomer')
                .catch(this.preErrorHandler)

            this.levels.forEach(level => {
                // Calcula os progressValues
                if (level.trigger_amount > 0)
                    this.progressValues.push((Number(this.progressAccumulatedAmount) / Number(level.trigger_amount)) * 100)
                else
                    this.progressValues.push(100)
            })

            this.levels.forEach(level => { this.triggers.push(level.trigger_amount) })

            if(this.triggers) {
                this.lastTriggerValue = this.triggers[this.triggers.length - 1]
            }

            if (!this.progressLevel) {
                this.hasError = true
                this.loading = false
                return
            }

            // Busca os benefícios do nível em que o cliente se encontra
            this.benefits = await Benefit.list(this.progressLevel.id)
                .catch(this.preErrorHandler)

            this.loading = false
        },
        calculateLevelPositionBar(trigger_level_index) {
            // retornar a posição em % da barra de cada level.
            if(!this.lastTriggerValue || !this.triggers.length) return

            const amount     = this.triggers[trigger_level_index]
            const position   = 100 * (amount/this.lastTriggerValue)

            return position
        },
        computedProgressStyles(index) {
            if (index + 1 == 1) {
                return {
                    'display' : 'flex',
                    'position': 'absolute',
                    'left'    : `0%`,
                }
            }

            if (index + 1 === this.levels.length) {
                return {
                    'display': 'flex',
                    'justify-content': 'flex-end',
                }
            }

            const widthPosition = this.calculateLevelPositionBar(index)

            return {
                'display': 'flex',
                'transform': 'translate(-50%)',
                'margin-left': '0px',
                'position': 'absolute',
                'left': `${widthPosition}%`,
            }
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            levels: 'levels/levels',
            hasLevels: 'levels/hasLevels',
            hidePoints: 'company/hide_points',
        }),
        levelsSystem() {
            return this.user.levels_system
        },
        progressLevel() {
            return this.user.transition_level
        },
        progressAccumulatedAmount() {
            return this.levelsSystem == this.$constants.getConstant('LOOKBACK_LEVELS_SYSTEM') ? 
                this.user.consolidated_accumulated_amount :
                this.user.transition_accumulated_amount
        },
        globalUserLevel() {
            const position = 100 * (this.progressAccumulatedAmount/this.lastTriggerValue)

            return position > 100 ? 100 : position
        },
    },
}
</script>

<style scoped lang="scss">
.progress-liner {
    border-radius: .1875rem;
    width: 100%;
    padding: 0 12px !important;
    margin-right: 12px !important;
}

.v-progress-linear.progress-liner.theme--light {
    width: 100% !important;
}

.title-content {
    width: 100%;
}

.vertical-progress {
    max-height: 100%;
}

.vertical-line:after {
  content: "";
  background: #F4F1F1;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 1px;
}

.vertical-line:nth-last-child(1):after {
  display: none;
}
</style>