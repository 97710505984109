var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasLevels
        ? _c(
            "v-container",
            { staticClass: "d-none d-md-flex d-lg-flex" },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    staticClass: "mx-auto",
                    attrs: { indeterminate: "" }
                  })
                : _c(
                    "v-card",
                    { attrs: { elevation: "0", width: "100%" } },
                    [
                      _vm.hasError
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-0",
                              attrs: { type: "error", dense: "", block: "" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.HomeView.levels.lookback.error"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "v-col",
                            { staticClass: "px-4" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "primary--text font-weight-bold subtitle-1 pb-3"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.HomeView.levels.lookback.subtitle",
                                          { levelName: _vm.progressLevel.name }
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              !_vm.hidePoints
                                ? _c("v-card-subtitle", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tc(
                                          "views.HomeView.levels.points",
                                          _vm.progressAccumulatedAmount
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-8 pa-0" },
                                    _vm._l(_vm.benefits, function(
                                      benefit,
                                      index
                                    ) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: benefit.id,
                                          staticClass: "pa-0"
                                        },
                                        [
                                          _c("BenefitListItem", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              readonly: "",
                                              benefit: benefit,
                                              index: index,
                                              completeDescription: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "p-relative" },
                                    [
                                      _vm._l(_vm.levels, function(item, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "py-0 ml-n1",
                                              style: _vm.computedProgressStyles(
                                                index
                                              )
                                            },
                                            _vm._l(index + 1, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "div",
                                                { key: index },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: 20,
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("far fa-star")]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  ),
                                  _c("div", { staticClass: "clear-fix py-1" }),
                                  _c("v-progress-linear", {
                                    staticClass: "progress-liner",
                                    attrs: {
                                      "background-color": "progress_linear",
                                      value: _vm.globalUserLevel,
                                      color: "primary"
                                    }
                                  }),
                                  _c("div", { staticClass: "clear-fix py-1" }),
                                  _c(
                                    "div",
                                    { staticClass: "p-relative" },
                                    [
                                      _vm._l(_vm.levels, function(item, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: index + "-" + item.name,
                                              staticClass: "py-0",
                                              style: _vm.computedProgressStyles(
                                                index
                                              )
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "primary_font--text pa-0",
                                                  style:
                                                    item.name ===
                                                    _vm.progressLevel.name
                                                      ? "color: var(--v-primary-base) !important"
                                                      : ""
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            ]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  ),
                                  _c("div", { staticClass: "clear-fix" }),
                                  _c(
                                    "v-row",
                                    _vm._l(_vm.levels, function(item, index) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: index + "-" + item.id,
                                          staticClass: "vertical-line"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "mt-12 px-0" },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "subtitle-1 pa-0 mt-10 font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "views.HomeView.levels.LevelBenefit.title",
                                                          {
                                                            level_name:
                                                              item.name
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              !_vm.hidePoints
                                                ? _c("LevelRangePoints", {
                                                    staticClass:
                                                      "primary--text mb-3",
                                                    attrs: { level: item }
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "d-block d-md-none", attrs: { fluid: "" } },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                staticClass: "mx-auto",
                attrs: { indeterminate: "" }
              })
            : _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.hasError
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-0",
                              attrs: { type: "error", dense: "", block: "" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.HomeView.levels.lookback.error"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "v-card",
                            {
                              staticClass: "card",
                              attrs: { outlined: "", width: "100%" }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0 title-content" },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "primary--text font-weight-bold pb-0"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.HomeView.levels.lookback.subtitle",
                                              {
                                                levelName:
                                                  _vm.progressLevel.name
                                              }
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  !_vm.hidePoints
                                    ? _c(
                                        "v-card-subtitle",
                                        { staticClass: "subtitle-1 pt-2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tc(
                                                "views.Level.points",
                                                _vm.progressAccumulatedAmount
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-10",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    _vm._l(_vm.benefits, function(
                                      benefit,
                                      index
                                    ) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: benefit.id,
                                          staticClass: "pa-0",
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            sm: "12",
                                            md: "6",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("BenefitListItem", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              readonly: "",
                                              benefit: benefit,
                                              index: index,
                                              completeDescription: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vertical-progress" },
                                    [
                                      _c("VerticalProgressBar", {
                                        staticClass: "mx-3 vertical-progress",
                                        attrs: { value: _vm.globalUserLevel }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0 ma-0" },
                                    _vm._l(_vm.levels, function(item, index) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: index + "-primary",
                                          staticClass: "pa-0 ml-4 mb-6"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex mb-1" },
                                            _vm._l(index + 1, function(
                                              item,
                                              i
                                            ) {
                                              return _c(
                                                "span",
                                                { key: i + "-secundary" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: 22,
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("far fa-star")]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            0
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.progressLevel.id == item.id
                                                  ? "primary--text"
                                                  : "primary_font--text"
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "subtitle-2 pa-0 flex-column mt-n2"
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "subtitle-1 pa-0 font-weight-medium"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.HomeView.levels.LevelBenefit.title",
                                                              {
                                                                level_name:
                                                                  item.name
                                                              }
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  !_vm.hidePoints
                                                    ? _c("LevelRangePoints", {
                                                        staticClass:
                                                          "primary--text",
                                                        attrs: { level: item }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mt-4 mr-6"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.description)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }