<template>
    <div v-if="level.next && level.next.amount">
        {{ `${roundNumber(level.trigger_amount, 0)} - ${roundNumber(level.next.amount - 1, 0)}` }}
        {{ $t('views.Level.LevelBenefit.points') }}
    </div>
    <div v-else>
        {{ `+ ${roundNumber(level.trigger_amount, 0)}` }} {{ $t('views.Level.LevelBenefit.points') }}
    </div>
</template>

<script>
import { roundNumber } from '@/utils/mathUtils'
import Level           from '@/models/Level'

export default {
    props: {
        level: {
            type: Level, 
            required: true,
        },
    },
    methods: {
        roundNumber(...args) {
            return roundNumber(...args)
        },
    },
}
</script>