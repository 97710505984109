var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasError
    ? _c(
        "v-alert",
        { staticClass: "ma-0", attrs: { type: "error", dense: "", block: "" } },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("components.cards.ConsolidatedLevelCard.error")) +
              " "
          )
        ]
      )
    : _c(
        "v-card",
        {
          staticClass: "pa-1 py-md-3 px-md-4",
          attrs: {
            elevation: "0",
            width: "100%",
            outlined: _vm.$vuetify.breakpoint.mdAndUp ? false : true
          }
        },
        [
          _c(
            "v-card-title",
            {
              class:
                "pb-0 primary--text font-weight-bold " +
                (_vm.$vuetify.breakpoint.mdAndUp ? "subtitle-1" : "") +
                " "
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "views.HomeView.levels.renovation.consolidated.subtitle",
                      { levelName: _vm.consolidatedLevel.name }
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "v-card-subtitle",
            { staticClass: "mt-0 mb-2" },
            [
              _c("i18n", {
                attrs: { path: _vm.consolidatedTextPath },
                scopedSlots: _vm._u([
                  {
                    key: "pointsToGuaranteeLevel",
                    fn: function() {
                      return [
                        _c("b", { staticClass: "primary_font--text" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dynamicPoint")(_vm.pointsToGuaranteeLevel)
                            )
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "daysToGuaranteeLevel",
                    fn: function() {
                      return [
                        _c("b", { staticClass: "primary_font--text" }, [
                          _vm._v(_vm._s(_vm.daysToResetLevel + " dias"))
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "levelName",
                    fn: function() {
                      return [
                        _c("b", { staticClass: "primary--text" }, [
                          _vm._v(_vm._s(_vm.consolidatedLevel.name))
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "max",
                    fn: function() {
                      return [
                        _c("b", { staticClass: "primary_font--text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "views.HomeView.levels.renovation.consolidated.text.maximum_level"
                              )
                            )
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4 px-4" },
            [
              _c(
                "div",
                {
                  style: _vm.consolidatedProgressStyles,
                  attrs: { clas: "d-flex" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "primary--text d-flex justify-end" },
                    [_vm._v(_vm._s(_vm.consolidatedProgress + "%"))]
                  )
                ]
              ),
              _c("v-progress-linear", {
                staticClass: "progress-liner-styles my-1",
                attrs: {
                  "background-color": "progress_linear",
                  value: _vm.consolidatedProgress
                }
              }),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-space-between text-capitalize secondary_font--text customer-current-level"
                },
                [
                  _c("span", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.consolidatedProgressFromName))
                  ]),
                  _c("span", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.consolidatedProgressToName))
                  ])
                ]
              )
            ],
            1
          ),
          _vm.benefits && _vm.benefits.length
            ? _c(
                "div",
                [
                  _c("v-divider", { staticClass: "d-md-none mt-5 mb-4 mx-4" }),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(_vm.benefits, function(benefit, index) {
                      return _c(
                        "v-col",
                        {
                          key: benefit.id,
                          attrs: {
                            cols: "12",
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "3"
                          }
                        },
                        [
                          _c("BenefitListItem", {
                            staticClass: "mb-2",
                            attrs: {
                              readonly: "",
                              benefit: benefit,
                              index: index,
                              completeDescription: ""
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }