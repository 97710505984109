<template>
    <div id="progress-vertical-bar" class="progress-bar">
        <div class="progress-bar" :style="barProps" ></div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Number],
                required: true,
            },
            // Verificar na FID-[1050]
            // color: {
            //     type: String,
            //     required: true,
            // },
        },
        methods: {
            checkValue() {
                if (this.value > 100)
                    return 100

                if (this.value < 0)
                    return 0

                return this.value
            },
        },
        computed: {
            barProps() {
                const progressValue = this.checkValue()

                return {
                    'background-color': 'var(--v-primary-base)',
                    'height': `${progressValue}%`,
                }
            }
        },
    }
</script>

<style scoped>
#progress-vertical-bar {
    height: 100% !important;
    width: .25rem;
    border-radius: .1875rem;
    background: #F4F1F1;
}

.progress-bar {
    border-radius: .1875rem;
}
</style>