var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    staticClass: "mx-auto",
                    attrs: { indeterminate: "" }
                  })
                : _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c("ConsolidatedLevelCard", {
                            attrs: {
                              consolidatedLevel: _vm.consolidatedLevel,
                              transitionLevel: _vm.transitionLevel,
                              consolidatedAccumulatedAmount:
                                _vm.consolidatedAccumulatedAmount,
                              transitionAccumulatedAmount:
                                _vm.transitionAccumulatedAmount
                            }
                          })
                        ],
                        1
                      ),
                      _vm.progressItems.length
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "0", width: "100%" } },
                                [
                                  _vm.hasError
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "ma-0",
                                          attrs: {
                                            type: "error",
                                            dense: "",
                                            block: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.HomeView.levels.renovation.transition.error"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-col",
                                        { staticClass: "px-4" },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "primary--text font-weight-bold subtitle-1"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.HomeView.levels.renovation.transition.subtitle",
                                                      {
                                                        level_name:
                                                          _vm.transitionLevel
                                                            .name
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          !_vm.hidePoints
                                            ? _c("v-card-subtitle", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "views.HomeView.levels.points",
                                                      _vm.transitionAccumulatedAmount
                                                    )
                                                  )
                                                )
                                              ])
                                            : _c("v-card-subtitle", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.HomeView.levels.renovation.prizes.hide_points.up_level_to_receive"
                                                    )
                                                  )
                                                )
                                              ]),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "p-relative" },
                                                [
                                                  _vm._l(
                                                    _vm.progressItems,
                                                    function(item, index) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "py-0 ml-n1",
                                                            style: _vm.computedTransitionProgressStyles(
                                                              index
                                                            )
                                                          },
                                                          _vm._l(
                                                            index + 1,
                                                            function(
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        size: 20,
                                                                        color:
                                                                          "primary"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "far fa-star"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c("div", {
                                                staticClass: "clear-fix py-1"
                                              }),
                                              _c("v-progress-linear", {
                                                staticClass: "progress-liner",
                                                attrs: {
                                                  "background-color":
                                                    "progress_linear",
                                                  value: _vm.globalUserLevel,
                                                  color: "primary"
                                                }
                                              }),
                                              _c("div", {
                                                staticClass: "clear-fix py-1"
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "p-relative" },
                                                [
                                                  _vm._l(
                                                    _vm.progressItems,
                                                    function(item, index) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            key:
                                                              index +
                                                              "-" +
                                                              item.name,
                                                            staticClass: "py-0",
                                                            style: _vm.computedTransitionProgressStyles(
                                                              index
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                class:
                                                                  "pa-0 " +
                                                                  (!item.level_id ||
                                                                  _vm.transitionAccumulatedAmount >=
                                                                    item.amount
                                                                    ? "primary--text"
                                                                    : "primary_font--text")
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "v-row",
                                                _vm._l(
                                                  _vm.uniqueProgressItems,
                                                  function(item, index) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key:
                                                          index + "-" + item.id,
                                                        staticClass:
                                                          "vertical-line",
                                                        attrs: {
                                                          cols: "12",
                                                          md: _vm.colsSize
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "mt-12 px-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "subtitle-1 pa-0 mt-10 font-weight-medium"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column"
                                                              },
                                                              [
                                                                !_vm.hidePoints
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "caption secondary_font--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "views.HomeView.levels.renovation.prizes.receive_with"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          item.amounts,
                                                                          function(
                                                                            amount,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  index +
                                                                                  "-" +
                                                                                  item.id +
                                                                                  "-" +
                                                                                  amount
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-3 primary--text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "dynamicPoint"
                                                                                        )(
                                                                                          amount
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "caption secondary_font--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "views.HomeView.levels.renovation.prizes.hide_points.receive_with"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mt-4 caption"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.description
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : !_vm.hidePoints
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "0", width: "100%" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold subtitle-1"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.HomeView.levels.level_balance.amount_balance.label"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "views.HomeView.levels.points",
                                              _vm.transitionAccumulatedAmount
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "d-block", attrs: { fluid: "" } },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    staticClass: "mx-auto",
                    attrs: { indeterminate: "" }
                  })
                : _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c("ConsolidatedLevelCard", {
                            attrs: {
                              consolidatedLevel: _vm.consolidatedLevel,
                              transitionLevel: _vm.transitionLevel,
                              consolidatedAccumulatedAmount:
                                _vm.consolidatedAccumulatedAmount,
                              transitionAccumulatedAmount:
                                _vm.transitionAccumulatedAmount
                            }
                          })
                        ],
                        1
                      ),
                      _vm.progressItems.length
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.hasError
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "ma-0",
                                      attrs: {
                                        type: "error",
                                        dense: "",
                                        block: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.HomeView.levels.renovation.transition.error"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-1",
                                      attrs: { outlined: "" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold pb-0"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.HomeView.levels.renovation.transition.subtitle"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      !_vm.hidePoints
                                        ? _c(
                                            "v-card-subtitle",
                                            {
                                              staticClass:
                                                "pt-2 font-weight-bold primary_font--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tc(
                                                    "views.Level.points",
                                                    _vm.transitionAccumulatedAmount
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "d-flex mt-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "vertical-progress"
                                            },
                                            [
                                              _c("VerticalProgressBar", {
                                                staticClass:
                                                  "mx-3 vertical-progress",
                                                attrs: {
                                                  value: _vm.globalUserLevel
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0 ma-0" },
                                            _vm._l(_vm.progressItems, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: index + "-primary",
                                                  staticClass: "pa-0 ml-4 mb-6"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "d-flex mb-1"
                                                    },
                                                    _vm._l(index + 1, function(
                                                      item,
                                                      i
                                                    ) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: i + "-secundary"
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                size: 22,
                                                                color: "primary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "far fa-star"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }),
                                                    0
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "subtitle-2 pa-0 flex-column mt-n2"
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              class:
                                                                "subtitle-1 pa-0 font-weight-medium " +
                                                                (!item.level_id ||
                                                                _vm.transitionAccumulatedAmount >=
                                                                  item.amount
                                                                  ? "primary--text"
                                                                  : "primary_font--text")
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          !_vm.hidePoints
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "caption secondary_font--text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "views.HomeView.levels.renovation.prizes.receive_with"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    item.amounts,
                                                                    function(
                                                                      amount,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key:
                                                                            index +
                                                                            "-" +
                                                                            item.id +
                                                                            "-" +
                                                                            amount
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mb-3 primary--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "dynamicPoint"
                                                                                  )(
                                                                                    amount
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              class:
                                                                (item.amounts &&
                                                                item.amounts
                                                                  .length
                                                                  ? "mt-2"
                                                                  : "mt-0") +
                                                                " mr-6"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.description
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "0", width: "100%" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold subtitle-1"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.HomeView.levels.level_balance.amount_balance.label"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "views.HomeView.levels.points",
                                              _vm.transitionAccumulatedAmount
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }