<template>
    <v-alert v-if="hasError" type="error" dense block class="ma-0">
        {{ $t('components.cards.ConsolidatedLevelCard.error') }}
    </v-alert>
    <v-card v-else elevation="0" class="pa-1 py-md-3 px-md-4" width="100%" :outlined="$vuetify.breakpoint.mdAndUp ? false : true">
        <v-card-title :class="`pb-0 primary--text font-weight-bold ${$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : ''} `">
            {{ $t('views.HomeView.levels.renovation.consolidated.subtitle', { levelName: consolidatedLevel.name }) }}
        </v-card-title>
        <v-card-subtitle class="mt-0 mb-2">
            <i18n :path="consolidatedTextPath">
                <template #pointsToGuaranteeLevel>
                    <b class="primary_font--text">{{ pointsToGuaranteeLevel | dynamicPoint }}</b>
                </template>

                <template #daysToGuaranteeLevel>
                    <b class="primary_font--text">{{ `${daysToResetLevel} dias` }}</b>
                </template>

                <template #levelName>
                    <b class="primary--text">{{ consolidatedLevel.name }}</b>
                </template>

                <template #max>
                    <b class="primary_font--text">{{ $t('views.HomeView.levels.renovation.consolidated.text.maximum_level') }}</b>
                </template>
            </i18n>
        </v-card-subtitle>

        <div class="mb-4 px-4">
            <div clas="d-flex" :style="consolidatedProgressStyles">
                <div class="primary--text d-flex justify-end">{{ `${consolidatedProgress}%` }}</div>
            </div>
            <v-progress-linear 
                background-color="progress_linear"
                class="progress-liner-styles my-1"
                :value="consolidatedProgress"
            ></v-progress-linear>
            <div class="d-flex justify-space-between text-capitalize secondary_font--text customer-current-level">
                <span class="caption">{{ consolidatedProgressFromName }}</span> 
                <span class="caption">{{ consolidatedProgressToName }}</span>
            </div>
        </div>
        
        <div v-if="benefits && benefits.length">
            <v-divider class="d-md-none mt-5 mb-4 mx-4"></v-divider>
            <v-row no-gutters>
                <v-col v-for="(benefit, index) in benefits" :key="benefit.id" cols="12" xs="12" sm="12" md="6" lg="3">
                    <BenefitListItem class="mb-2" readonly :benefit="benefit" :index="index" completeDescription />
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import BenefitListItem      from '@/shared/components/listItems/BenefitListItem'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Benefit              from '@/models/Benefit'
import Level                from '@/models/Level'
import { mapGetters } from 'vuex'

export default {
    name: 'ConsolidatedLevelCard',
    props: {
        consolidatedLevel: {
            type: Level,
            required: true,
        },
        transitionLevel: {
            type: Level,
            required: true,
        },
        consolidatedAccumulatedAmount: {
            type: Number,
            required: true,
        },
        transitionAccumulatedAmount: {
            type: Number,
            required: true,
        },
    },
    mixins: [ HasErrorHandlerMixin ],
    components: { BenefitListItem },
    data: () => ({
        benefits: [],
        loading : false,
        hasError: false, 
    }),
    async created() {
        await this.fetchData()
    },
    methods: {
        async fetchData() {
            this.loading  = true
            this.benefits = await Benefit.list(this.consolidatedLevel.id)
                .catch(this.preErrorHandler)
            this.loading  = false
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            levels: 'levels/levels',
            companyLevels: 'company/levels',
            hidePoints: 'company/hide_points',
        }),
        consolidatedProgress() {
            if (!this.isLevelGuaranteed)
                return Math.floor((this.transitionAccumulatedAmount / this.consolidatedLevel.trigger_amount) * 100)

            if (this.consolidatedLevel.isLast())
                return 100

            let r  = this.transitionAccumulatedAmount   - this.transitionLevel.trigger_amount
            let rr = this.transitionLevel.next.amount - this.transitionLevel.trigger_amount
            return Math.floor((r / rr) * 100)
        },
        consolidatedProgressFromName() {
            if (!this.isLevelGuaranteed)
                return this.$lodash.first(this.levels).name

            if (this.consolidatedLevel.isLast())
                return ''

            return this.consolidatedLevel.name
        },
        consolidatedProgressToName() {
            if (!this.isLevelGuaranteed)
                return this.consolidatedLevel.name

            if (this.consolidatedLevel.isLast())
                return this.$lodash.last(this.levels).name

            return this.$store.getters['levels/getLevelById'](this.consolidatedLevel.next.id).name
        },
        isLevelGuaranteed() {
            return this.consolidatedLevel.trigger_amount == this.transitionLevel.trigger_amount
        },
        pointsToGuaranteeLevel() {
            return !this.isLevelGuaranteed ? this.consolidatedLevel.trigger_amount - this.transitionAccumulatedAmount : 0
        },
        daysToResetLevel() {
            let nextReset = this.$moment().add(this.companyLevels.renovation_interval, 'days')
            let now = this.$moment()

            return nextReset.diff(now, 'days')
        },
        consolidatedTextPath() {
            let basePath = 'views.HomeView.levels.renovation.consolidated.text'
            if (this.hidePoints) {
                basePath += '.hide_points'
            }

            if (!this.isLevelGuaranteed) {
                if (this.daysToResetLevel >= 2)
                    return `${basePath}.guarantee_more_than_two_days`
                
                if (this.daysToResetLevel >= 1)
                    return `${basePath}.guarantee_between_one_and_two_days`

                return `${basePath}.guarantee_less_than_one_day`
            }
            
            if (this.consolidatedLevel.isLast())
                return `${basePath}.maximum`

            return `${basePath}.up`
        },
        consolidatedProgressStyles() {
            const widthPosition = this.consolidatedProgress
            return {
                'position': 'relative',
                'padding': '0 0 0 22px',
                'width': `${widthPosition}%`,
            }
        },
    },
}
</script>