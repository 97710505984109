<template>
    <div>
        <!-- Desktop version -->
        <v-container fluid v-if="$vuetify.breakpoint.mdAndUp">
            <v-progress-circular class="mx-auto" indeterminate v-if="loading"/>
            <v-row v-else no-gutters>
                <v-col cols="12" class="mb-2">
                    <ConsolidatedLevelCard
                        :consolidatedLevel="consolidatedLevel"
                        :transitionLevel="transitionLevel"
                        :consolidatedAccumulatedAmount="consolidatedAccumulatedAmount"
                        :transitionAccumulatedAmount="transitionAccumulatedAmount"
                    ></ConsolidatedLevelCard>
                </v-col>

                <!-- Progresso de prêmios -->
                <v-col cols="12" v-if="progressItems.length">
                    <v-card elevation="0" width="100%">
                        <v-alert v-if="hasError" type="error" dense block class="ma-0">
                            {{ $t('views.HomeView.levels.renovation.transition.error') }}
                        </v-alert>
                        <v-col v-else class="px-4">
                            <v-card-title class="primary--text font-weight-bold subtitle-1">
                                {{ $t('views.HomeView.levels.renovation.transition.subtitle', { level_name: transitionLevel.name }) }}
                            </v-card-title>

                            <v-card-subtitle v-if="!hidePoints">{{ $tc('views.HomeView.levels.points', transitionAccumulatedAmount) }}</v-card-subtitle>
                            <v-card-subtitle v-else>{{ $t('views.HomeView.levels.renovation.prizes.hide_points.up_level_to_receive') }}</v-card-subtitle>

                            <v-card-text>
                                <!-- Estrelas -->
                                <div class="p-relative">
                                    <template v-for="(item, index) in progressItems">
                                        <div class="py-0 ml-n1" :style="computedTransitionProgressStyles(index)" :key="index">
                                            <div v-for="(item, index) in (index+1)" :key="index">
                                                <v-icon :size="20" color="primary">far fa-star</v-icon>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <div class="clear-fix py-1"></div>

                                <!-- Barra de progresso -->
                                <v-progress-linear
                                    background-color="progress_linear"
                                    class="progress-liner"
                                    :value="globalUserLevel"
                                    color="primary"
                                ></v-progress-linear>

                                <div class="clear-fix py-1"></div>

                                <!-- Nome dos prêmios -->
                                <div class="p-relative">
                                    <template
                                        v-for="(item, index) in progressItems"
                                    >
                                        <div class="py-0" :style="computedTransitionProgressStyles(index)" :key="`${index}-${item.name}`" >
                                            <div :class="`pa-0 ${!item.level_id || transitionAccumulatedAmount >= item.amount ? 'primary--text' : 'primary_font--text'}`">
                                                {{ item.title }}
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <!-- Descrições dos prêmios -->
                                <v-row>
                                    <v-col v-for="(item, index) in uniqueProgressItems" :key="`${index}-${item.id}`" 
                                        cols="12"
                                        :md="colsSize"
                                        class="vertical-line"
                                    >
                                        <v-col class="mt-12 px-0">
                                            <v-card-title class="subtitle-1 pa-0 mt-10 font-weight-medium">
                                                {{ item.title }}
                                            </v-card-title>

                                            <div class="d-flex flex-column">
                                                <div v-if="!hidePoints">
                                                    <span class="caption secondary_font--text">{{ $t('views.HomeView.levels.renovation.prizes.receive_with') }}</span>
                                                    <div v-for="(amount, index) in item.amounts" :key="`${index}-${item.id}-${amount}`">
                                                        <span class="mb-3 primary--text">{{ amount | dynamicPoint }}</span>
                                                    </div>
                                                </div>
                                                <span v-else class="caption secondary_font--text">{{ $t('views.HomeView.levels.renovation.prizes.hide_points.receive_with') }}</span>

                                                <span class="mt-4 caption">{{ item.description }}</span>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col cols="12" v-else-if="!hidePoints">
                    <v-card elevation="0" width="100%">
                        <v-card-text>
                            <v-card-title class="primary--text font-weight-bold subtitle-1">
                                {{ $t('views.HomeView.levels.level_balance.amount_balance.label') }} 
                            </v-card-title>
                            <v-card-subtitle>{{ $tc('views.HomeView.levels.points', transitionAccumulatedAmount) }}</v-card-subtitle>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- Mobile version -->
        <v-container fluid class="d-block" v-else>
            <v-progress-circular class="mx-auto" indeterminate v-if="loading"/>
            <v-row v-else no-gutters>
                <v-col cols="12" class="mb-2">
                    <ConsolidatedLevelCard
                        :consolidatedLevel="consolidatedLevel"
                        :transitionLevel="transitionLevel"
                        :consolidatedAccumulatedAmount="consolidatedAccumulatedAmount"
                        :transitionAccumulatedAmount="transitionAccumulatedAmount"
                    ></ConsolidatedLevelCard>
                </v-col>
                
                <!-- Progresso de prêmios -->
                <v-col cols="12" v-if="progressItems.length">
                    <v-alert v-if="hasError" type="error" dense block class="ma-0">
                        {{ $t('views.HomeView.levels.renovation.transition.error') }}
                    </v-alert>
                    <v-card v-else outlined class='pa-1'>
                        <v-card-title class="primary--text font-weight-bold pb-0">
                            {{ $t('views.HomeView.levels.renovation.transition.subtitle') }}
                        </v-card-title>
                        <v-card-subtitle v-if="!hidePoints" class="pt-2 font-weight-bold primary_font--text">{{ $tc('views.Level.points', transitionAccumulatedAmount) }}</v-card-subtitle>

                        <v-card-text class="d-flex mt-6">
                            <div class="vertical-progress">
                                <VerticalProgressBar :value="globalUserLevel" class="mx-3 vertical-progress" />
                            </div>

                            <v-col class="pa-0 ma-0">
                                <v-col class="pa-0 ml-4 mb-6" v-for="(item, index) in progressItems" :key="`${index}-primary`">
                                    <div class="d-flex mb-1">
                                        <span v-for="(item, i) in (index + 1)" :key="`${i}-secundary`">
                                            <v-icon :size="22" color="primary">far fa-star</v-icon>
                                        </span>
                                    </div>
                                    <v-col>
                                        <v-row class="subtitle-2 pa-0 flex-column mt-n2">
                                            <v-card-title :class="`subtitle-1 pa-0 font-weight-medium ${!item.level_id || transitionAccumulatedAmount >= item.amount ? 'primary--text' : 'primary_font--text'}`">
                                                {{ item.title }}
                                            </v-card-title>
                                            
                                            <div v-if="!hidePoints">
                                                <span class="caption secondary_font--text">{{ $t('views.HomeView.levels.renovation.prizes.receive_with') }}</span>
                                                <div v-for="(amount, index) in item.amounts" :key="`${index}-${item.id}-${amount}`">
                                                    <span class="mb-3 primary--text">{{ amount | dynamicPoint }}</span>
                                                </div>
                                            </div>
                                            
                                            <div :class="`${item.amounts && item.amounts.length ? 'mt-2' : 'mt-0'} mr-6`">{{ item.description }}</div>
                                        </v-row>
                                    </v-col>
                                </v-col>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" v-else>
                    <v-card elevation="0" width="100%">
                        <v-card-text>
                            <v-card-title class="primary--text font-weight-bold subtitle-1">
                                {{ $t('views.HomeView.levels.level_balance.amount_balance.label') }} 
                            </v-card-title>
                            <v-card-subtitle>{{ $tc('views.HomeView.levels.points', transitionAccumulatedAmount) }}</v-card-subtitle>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ConsolidatedLevelCard from '@/components/cards/ConsolidatedLevelCard'
import VerticalProgressBar   from '@/components/progressBars/LevelVerticalProgressBar'
import HasErrorHandlerMixin  from '@/mixins/HasErrorHandlerMixin'
import Prize                 from '@/models/Prize'
import { mapGetters }        from 'vuex'

export default {
    name: 'LevelForRenovation',
    mixins: [ HasErrorHandlerMixin ],
    components: { VerticalProgressBar, ConsolidatedLevelCard },
    data: vm => ({
        hasError           : false,
        loading            : true,
        progressValues     : [],
        progressItems      : [],
        uniqueProgressItems: [],
        triggers           : [],
        levelsWithPrize    : [],
        lastTriggerValue   : null,
    }),
    async created() {
        if (!this.hasLevels)
            this.$router.push({ name: 'home' })

        await this.fetchData()
    },
    methods: {
        async fetchData() {
            this.loading = true

            if (!this.levels || !this.levels.length) {
                this.hasError = true
                this.loading  = false
                return
            }

            // Atualiza o cliente a fim de garantir que o nível exibido é o mais novo possível
            await this.$store.dispatch('auth/fetchCurrentCustomer')
                .catch(this.preErrorHandler)

            this.levels.forEach(level => {
                // Calcula os progressValues
                if (level.trigger_amount > 0)
                    this.progressValues.push((Number(this.transitionAccumulatedAmount) / Number(level.trigger_amount)) * 100)
                else
                    this.progressValues.push(100)
            })

            // Seta os items que serão exibidos na barra de progresso
            this.levelsWithPrize = this.levels.filter(level => level.prize instanceof Prize)
            this.levelsWithPrize.forEach((level, index) => {
                this.triggers.push(level.trigger_amount)
                let progressItem = {
                    level_id    : level.id,
                    prize_id    : level.prize.id,
                    title       : level.prize.name,
                    description : level.prize.description,
                    amounts     : [ level.trigger_amount ],
                }
                this.progressItems.push(progressItem)
                let isUnique = this.uniqueProgressItems.some(item => { 
                    if (progressItem.prize_id === item.prize_id) {
                        item.amounts.push(progressItem.amounts[0])
                        return true
                    }
                    return false
                })
                if (!isUnique)
                    this.uniqueProgressItems.push(progressItem)
            })

            // Trata caso em que o primeiro nível não tem prêmio relacionado
            if (this.progressItems.length && this.progressItems[0].level_id != this.levels[0].id) {
                this.triggers.unshift(0)

                const description = !this.hidePoints ? this.$t('views.HomeView.levels.renovation.transition.first_item_description') : this.$t('views.HomeView.levels.renovation.transition.hide_points.first_item_description')
                this.progressItems.unshift({
                    description,
                    title: this.$t('views.HomeView.levels.renovation.transition.first_item_title'),
                    amount: 0,
                })
            }

            if (this.triggers)
                this.lastTriggerValue = this.triggers[this.triggers.length - 1]

            this.loading = false
        },
        calculateLevelPositionBar(trigger_level_index) {
            // retornar a posição em % da barra de cada level.
            if (!this.lastTriggerValue || !this.triggers.length) return

            const amount     = this.triggers[trigger_level_index]
            const position   = 100 * (amount/this.lastTriggerValue)

            return position
        },
        computedConsolidatedProgressStyles() {
            const widthPosition = this.consolidatedProgress

            return {
                'position': 'relative',
                'padding': '0 0 0 22px',
                'width': `${widthPosition}%`,
            }
        },
        computedTransitionProgressStyles(index) {
             if (index + 1 == 1) {
                return {
                    'position': 'absolute',
                    'display' : 'flex',
                    'left'    : '0%',
                }
            }

            if (index + 1 === this.progressItems.length) {
                return {
                    'display': 'flex',
                    'justify-content': 'flex-end',
                }
            }

            const widthPosition = this.calculateLevelPositionBar(index)


            return {
                'display': 'flex',
                'transform': 'translate(-50%)',
                'margin-left': '0px',
                'position': 'absolute',
                'left': `${widthPosition}%`,
            }
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            levels: 'levels/levels',
            hasLevels: 'levels/hasLevels',
            hidePoints: 'company/hide_points',
        }),
        consolidatedLevel() {
            return this.$lodash.get(this.user, 'consolidated_level' || {})
        },
        transitionLevel() {
            return this.$lodash.get(this.user, 'transition_level' || {})
        },
        consolidatedAccumulatedAmount() {
            return this.$lodash.get(this.user, 'consolidated_accumulated_amount', 0)
        },
        transitionAccumulatedAmount() {
            return this.$lodash.get(this.user, 'transition_accumulated_amount', 0)
        },
        globalUserLevel() {
            const position = 100 * (this.transitionAccumulatedAmount / this.lastTriggerValue)
            return position > 100 ? 100 : position
        },
        colsSize() {
            let colSize = 2

            if (this.levelsWithPrize.length)
                colSize = (this.levelsWithPrize.length <= 4) ? ( 12 / this.levelsWithPrize.length ) : colSize

            return colSize
        },
    },
}
</script>

<style scoped lang="scss">
.progress-liner {
    border-radius: .1875rem;
    width: 100%;
    padding: 0 12px !important;
    margin-right: 12px !important;
}

.v-progress-linear.progress-liner.theme--light {
    width: 100% !important;
}

.vertical-progress {
    max-height: 100%;
}

.vertical-line:after {
  content: "";
  background: #F4F1F1;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 1px;
}

.vertical-line:nth-last-child(1):after {
  display: none;
}
</style>