var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-breadcrumbs",
            {
              staticClass: "pl-0 pb-1",
              class: _vm.isLightTheme ? "breadcrumb-item" : "",
              attrs: { items: _vm.breadcrumbs }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "breadcrumbs-chevro",
                  attrs: { slot: "divider" },
                  slot: "divider"
                },
                [_vm._v("fas fa-chevron-right")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "section-title font-weight-bold primary_font--text"
            },
            [_vm._v(_vm._s(_vm.$t("views.Level.title")))]
          )
        ],
        1
      ),
      _vm.settings.levels_system ==
      _vm.$constants.getConstant("LOOKBACK_LEVELS_SYSTEM")
        ? _c("LevelForLookback")
        : _c("LevelForRenovation")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }