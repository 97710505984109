var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.level.next && _vm.level.next.amount
    ? _c("div", [
        _vm._v(
          " " +
            _vm._s(
              _vm.roundNumber(_vm.level.trigger_amount, 0) +
                " - " +
                _vm.roundNumber(_vm.level.next.amount - 1, 0)
            ) +
            " " +
            _vm._s(_vm.$t("views.Level.LevelBenefit.points")) +
            " "
        )
      ])
    : _c("div", [
        _vm._v(
          " " +
            _vm._s("+ " + _vm.roundNumber(_vm.level.trigger_amount, 0)) +
            " " +
            _vm._s(_vm.$t("views.Level.LevelBenefit.points")) +
            " "
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }