<template>
    <div>
        <v-container>
            <v-breadcrumbs :class="isLightTheme ? 'breadcrumb-item': ''" class="pl-0 pb-1" :items="breadcrumbs">
               <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
            </v-breadcrumbs>

            <div class="section-title font-weight-bold primary_font--text">{{ $t('views.Level.title') }}</div>
        </v-container>

        <LevelForLookback v-if="settings.levels_system == $constants.getConstant('LOOKBACK_LEVELS_SYSTEM')" />
        <LevelForRenovation v-else />
    </div>
</template>

<script>
import LevelForRenovation from './content/LevelForRenovation'
import LevelForLookback from './content/LevelForLookback'
import RateColor        from '@/shared/mixins/RateColor'
import { roundNumber }  from '@/utils/mathUtils'
import { mapGetters } from 'vuex'

export default {
    name: 'LevelsView',
    mixins: [ RateColor ],
    components: { LevelForLookback, LevelForRenovation },
    data: vm => {
        return {
             breadcrumbs: [
                { text: vm.$t('drawer.home'), to: { name: 'home' } },
                { text: vm.$t('drawer.levels'), disabled: true },
            ],
        }
    },
    computed: {
        ...mapGetters({
            settings: 'company/settings',
        })
    },
}
</script>